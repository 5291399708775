export enum LocalesEntityEnum {
  ROUND = 'ROUND',
  QUESTION = 'QUESTION',
  ANSWER = 'ANSWER',
  SPORT_EVENT = 'SPORT_EVENT',
  TEAM = 'TEAM',
  BRAND_URL = 'BRAND_URL',
  PRIZE = 'PRIZE',
  LEADERBOARD = 'LEADERBOARD',
  STREAK = 'STREAK',
  UPSELL_TITLE = 'UPSELL_TITLE',
  UPSELL_DESCRIPTION = 'UPSELL_DESCRIPTION',
  UPSELL_BUTTON = 'UPSELL_BUTTON',
  UPSELL_WEB_LINK = 'UPSELL_WEB_LINK',
  UPSELL_MOBILE_LINK = 'UPSELL_MOBILE_LINK',
  LEADERBOARD_NAME = 'LEADERBOARD_NAME',
  TIER_NAME = 'TIER_NAME',
  DESCRIPTION = 'DESCRIPTION',
  PRIZE_TEXT = 'PRIZE_TEXT',
  PRIZE_LABEL_TEXT = 'PRIZE_LABEL_TEXT',
}
