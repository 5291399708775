import { Injectable } from '@angular/core';

import { LocalStorageEnum } from "@enums/LocalStorageEnum";
import { SettingsConfigItemModel } from "@models/SettingsConfigItemModel";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {


  setItem(prop: string, value: any): void {
    localStorage.setItem(prop, JSON.stringify(value));
  }

  getItem(prop: string): any {
    return JSON.parse(localStorage.getItem(prop));
  }

  removeItem(prop: string) {
    localStorage.removeItem(prop);
  }

  clear() {
    localStorage.clear();
  }

  getToken(): string {
    return this.getItem(LocalStorageEnum.TOKEN_KEY)
  }

  getRefreshToken(): string {
    return this.getItem(LocalStorageEnum.REFRESHTOKEN_KEY)
  }

  setToken(token: string) {
    this.setItem(LocalStorageEnum.TOKEN_KEY, token)
  }

  setRefreshToken(refreshToken: string) {
    this.setItem(LocalStorageEnum.REFRESHTOKEN_KEY, refreshToken)
  }

  logOut() {
    this.removeItem(LocalStorageEnum.TOKEN_KEY)
    this.removeItem(LocalStorageEnum.REFRESHTOKEN_KEY)
    this.removeItem(LocalStorageEnum.ROLE)
    if (this.getItem(LocalStorageEnum.TWO_FA)) {
      this.removeItem(LocalStorageEnum.TWO_FA)
    }
  }

  getSettingsConfig() {
    return this.getItem(LocalStorageEnum.SETTINGS);
  }

  setSettingsConfig(config: SettingsConfigItemModel[]) {
    this.setItem(LocalStorageEnum.SETTINGS, config)
  }

  getHash() {
    return this.getItem(LocalStorageEnum.HASH);
  }

  setHash(hash: string) {
    this.setItem(LocalStorageEnum.HASH, hash)
  }

  getChildWindowIds() {
    return this.getItem(LocalStorageEnum.CHILD_WINDOWS_IDS);
  }

  setChildWindowIds(childWindowsIds: string) {
    this.setItem(LocalStorageEnum.CHILD_WINDOWS_IDS, childWindowsIds);
  }

  removeChildWindowIds() {
    this.removeItem(LocalStorageEnum.CHILD_WINDOWS_IDS);
  }

  setTenantType(tenantType) {
    this.setItem(LocalStorageEnum.TENANT_TYPE, tenantType);
  }

  getTenantType() {
    return this.getItem(LocalStorageEnum.TENANT_TYPE);
  }

  removeTenantType() {
    this.removeItem(LocalStorageEnum.TENANT_TYPE);
  }
}
