import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextService {

  /**
   * Get string and return current string with capitalized first letter
   * @param string - string which need to be changed
   */
  capitalizeFirstLetter (string) {
    if (!string) return string;
    if(string.includes('_')) string = string.split('_').join(' ');
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  /**
   * Get string and return current string with capitalized first letter in each word
   * @param string - string which need to be changed
   */
  capitalizeFirstLetterInEachWord (string) {
    return string
      .split(' ')
      .map(word => {
        const lowerWord = word.toLowerCase();
        if (lowerWord === 'nft') return lowerWord.toUpperCase();
        return this.capitalizeFirstLetter(lowerWord);
      })
      .join(' ');
  }

  /**
   * Get string and return current string with replaced symbols -, _ ,and
   * @param name - string which need to be changed
   */
  createNameWithSpaces(name) {
    const pageName = name.replaceAll('-', ' ').replace(' and ', ' & ').replaceAll('_', ' ');
    const pageNameArray = pageName.split(' ');
    const capitalizedNameArray = pageNameArray.map(word => word.charAt(0).toUpperCase() + word.substring(1));
    return capitalizedNameArray.join(' ');
  }

  /**
   * Get string and return current string with replaced symbols -, _ ,and, and capitalized first letter
   * @param name - string which need to be changed
   */
  createNameWithSpaceAndCapitalizeFirstLetter(name) {
    if (!name) return;
    const capitalized = this.capitalizeFirstLetter(name);
    return this.createNameWithSpaces(capitalized);
  }

  hasSpacesAtStartOrEnd(text: string): boolean {
    return text.trim() !== text;
  }
}
